import React, { useEffect } from 'react'
import './presidential.css'
import img1 from '../../../Assets/pres/4.jpg'
import img2 from '../../../Assets/pres/1.jpg'
import img3 from '../../../Assets/pres/2.jpg'
import img4 from '../../../Assets/pres/3.jpg'
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Presidential = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
  return (
    <section className='presidential'>
        <div className="mainImg">
            <img src={img1} alt="" />
        </div>
        {isSmallScreen ? (
        <Carousel showThumbs={false}>
          <div>
            <img src={img2} alt="" />
          </div>
          <div>
            <img src={img3} alt="" />
          </div>
          <div>
            <img src={img4} alt="" />
          </div>
        </Carousel>
      ) : (
        <div data-aos="fade-up" className="secImg">
          <div className="secImg1">
            <img src={img2} alt="" />
          </div>
          <div className="secImg2">
            <img src={img3} alt="" />
            <img src={img4} alt="" />
          </div>
        </div>
      )}
        <div data-aos="fade-up" className="card grid">
            <h2>Presidential Suites</h2>
            <span>This spacious 91sqm suite boasts a private terrace, master bedroom, living/dining areas, powder room, pantry, marble bathroom, and stunning garden views, ideal for entertainment.</span>
            <h3>Amenities</h3>
            <ul>
                <li>Pick Up / Drop Off Service</li>
                <li>Room Jacuzzi</li>
                <li>Complimentary Wi-Fi</li>
                <li>24-Hour Room Service</li>
                <li>Complimentary Meals (Breakfast, Lunch, Dinner)</li>
                <li>Custom-Stocked Fridge/ Mini-Bar</li>
                <li>Complimentary Movie Library</li>
                <li>Non-alcoholic and alcoholic drinks upon request</li>
                <li>Sex Chair</li>
                <li>Toys & Costumes for role play</li>
            </ul>
            <h3>Presidential Exclusive</h3>
            <ul>
                <li>Bigger floor space (Compared to other room packages)</li>
                <li>Emperor bed, with Lansdowne Cashmere mattresses and custom designed headboard</li>
                <li>Spa Treatment</li>
                <li>Laundry Service</li>
                <li>Outdoor Pool</li>
                <li>Grilling Service</li>
                <li>On call staff stationed near the Hookup Room</li>
                <li>Fragrance Butler</li>
                <li>24-hour Personal Chef availability</li>
            </ul>
            <div className="buttons flex">
                <Link to='/book'>
                    <button className="btn">Book now</button>
                </Link>
                <Link to='/stay'>
                    <button className="btn">more rooms and suites</button>
                </Link>
            </div>
        </div>
    </section>
  )
}

export default Presidential
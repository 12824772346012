import React, {useEffect, useRef } from 'react'
import './footer.css'
import video2 from '../../Assets/video2.mp4'
import { FiSend } from "react-icons/fi";
import { FaHotel } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { FiChevronRight } from "react-icons/fi";
import Aos from 'aos';
import 'aos/dist/aos.css'
import emailjs from '@emailjs/browser';

const Footer = () => {
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yc6c4pd', 'template_h96mrd6', form.current, 'G678d5AZDYPDPQaDJ')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section className='footer'>
      <div className="videoDiv">
      </div>

      <div className="secContent container">
        <div  id='contact' className="contactDiv flex">
          <div data-aos="fade-up" className="text">
            <small>KEEP IN TOUCH</small>
            <h2>Book with us</h2>
          </div>

          <form ref={form} onSubmit={sendEmail} className="inputDiv flex">
            <input data-aos="fade-up" name="user_name" type="text" placeholder='Enter Your Name'/>
            <input data-aos="fade-up" name="user_email" type="email" placeholder='Enter Your Email Address'/>
            <input id='message' name="message" data-aos="fade-up" cols="30" rows="10" type="text" placeholder='Enter Your Message'/>
            <button data-aos="fade-up" className='btn flex' type='submit'>
            SEND <FiSend className='icon'/>
            </button>
          </form>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a className='logo flex'>
              <FaHotel  className="icon" />
                Hookup Room
              </a>
            </div>
            
            <div data-aos="fade-up" className="footerParagraph">
            
Hookup Room is one of the fastest growing online travel portals in across the world, offering lovers an extensive selection of hookup rooms, activities and travel services to meet every budget and activities of every kind at competitive rates. This website is operated by Hookup Room Intl., with its head office at 125 Deansgate, Manchester M3 2LH, United Kingdom.
            </div>

            <div data-aos="fade-up" className="footerSocials flex">
            <FiMail className='icon' />
            <TfiHeadphoneAlt className='icon'/>
           </div>
          </div>


          <div className="footerLinks grid">

            {/* Group One*/}
            <div data-aos="fade-up" data-aos-duration="3000"className="linkGroup">
              <span className="groupTitle">
                OUR AGENCY
              </span>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Services
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Insurance
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Agency
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Payment
              </li>

            </div>
            {/* Group Two*/}
            <div data-aos="fade-up" data-aos-duration="4000"className="linkGroup">
              <span className="groupTitle">
                PARTNERS
              </span>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Bookings
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Expedia
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  TripAdvisor
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Trivago
              </li>

            </div>
            {/* Group Three*/}
            <div data-aos="fade-up" data-aos-duration="5000"className="linkGroup">
              <span className="groupTitle">
                International
              </span>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  UK
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  USA
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Canada
              </li>

              <li className="footerList flex">
                <FiChevronRight className='icon' />
                  Australia
              </li>

            </div>
          </div>

          <div className="footerDiv flex">
            <small>BEST HOOKUP ROOM BOOKINGS</small>
            <small>COPYRIGHTS RESERVED - BOOK HOOKUP ROOM 2023</small>
          </div>



        </div>
      </div>
    </section>
  )
}

export default Footer
import React, {useState} from 'react'
import './navbar.css'
import { FaHotel } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [active, setActive] = useState('navBar')
  // Function to toggle navBar
  const showNav = ()=>{
    setActive('navBar activeNavbar')
  }
  // Function to close navBar
  const removeNavbar = ()=>{
    setActive('navBar')
  }

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToRoom = () => {
    const contactSection = document.getElementById('room');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToBook = () => {
    const contactSection = document.getElementById('book');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToHome = () => {
    const contactSection = document.getElementById('home');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className='navBarSection'>
      <header className="header flex">

        <div className="logoDiv">
          <Link to='/' onClick={scrollToHome}>
            <a className="logo flex">
              <h1><FaHotel  className="icon" /> Hookup Room</h1>
            </a>
          </Link>
        </div>

      <div className="navMain flex">
        <div className={active}>
            <ul className="navLists flex">
              <Link to='/' onClick={scrollToHome}>
                <li className="navItem">
                  <a  onClick={(removeNavbar)} className="navLink">Home</a>
                </li>
              </Link>
              <Link to='/stay' >
                <li className="navItem">
                  <a onClick={removeNavbar} className="navLink">Rooms</a>
                </li>
              </Link>
              <Link to='/' onClick={(scrollToContact)}>
                <li className="navItem">
                  <a onClick={removeNavbar} className="navLink">About</a>
                </li>
              </Link>
              <Link to='/' onClick={(scrollToContact)}>
                <li className="navItem">
                  <a onClick={removeNavbar} className="navLink">Contact</a>
                </li>
              </Link>
              <Link to='/book' onClick={(scrollToBook, removeNavbar)}>
                <button className='btn'>
                  <a href="#">BOOK NOW</a>
                </button>
          </Link>

            </ul>

            <div onClick={removeNavbar} className="closeNavbar">
              <AiFillCloseCircle className='icon'/>
            </div>

          </div>

          <div onClick={showNav}  className="toggleNavbar">
            <TbGridDots className="icon"/>
        </div>
        
      </div>
        
      </header>
    </section>
  )
}

export default Navbar
import React, { useState, useEffect, useCallback  } from 'react';
import './app.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { data } from './data.js';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Main from './Components/Main/Main';
import Book from './Components/Book/Book.jsx';
import Payment from './Components/Payment/Payment.jsx';
import Stay from './Components/Stay/Stay.jsx';
import Deluxe from './Components/Rooms/Deluxe/Deluxe.jsx';
import Premium from './Components/Rooms/Premium/Premium.jsx';
import Luxury from './Components/Rooms/Luxury/Luxury.jsx';
import Presidential from './Components/Rooms/Presidential/Presidential.jsx';



const App = () => {
  const [filteredData, setFilteredData] = useState(data);
  const [cityName, setCityName] = useState('New York');
  const [maxPrice, setMaxPrice] = useState(450);

  const handleSearch = ({ cityName, maxPrice }) => {
    setCityName(cityName);
    setMaxPrice(maxPrice);
  };

  useEffect(() => {
    let filteredResults = data;

    if (cityName) {
      filteredResults = filteredResults.filter((item) =>
        Array.isArray(item.location)
          ? item.location.some((loc) => loc.toLowerCase() === cityName)
          : item.location.toLowerCase() === cityName
      );
    }

    filteredResults = filteredResults.filter(
      ({ fees }) => parseFloat(fees) <= parseFloat(maxPrice)
    );

    setFilteredData(filteredResults);
  }, [cityName, maxPrice]);

  return (
    <Router>
        <Navbar />
        <Switch>
          <Route exact path="/book" component={Book} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/stay" component={Stay} />
          <Route exact path="/deluxe-room" component={Deluxe} />
          <Route exact path="/premium-room" component={Premium} />
          <Route exact path="/luxury-room" component={Luxury} />
          <Route exact path="/presidential-suites" component={Presidential} />
          <Route exact path="/">
            <Home onSearch={handleSearch} />
            <Main filteredData={filteredData} cityName={cityName} maxPrice={maxPrice} />
            <Footer />
          </Route>
        </Switch>
    </Router>
  );
};

export default App;

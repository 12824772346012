import React, { useEffect } from 'react'
import './premium.css'
import img1 from '../../../Assets/prem/4.jpg'
import img2 from '../../../Assets/prem/1.jpg'
import img3 from '../../../Assets/prem/2.jpg'
import img4 from '../../../Assets/prem/3.jpg'
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Premium = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
  return (
    <section className='premium'>
        <div className="mainImg">
            <img src={img1} alt="" />
        </div>
        {isSmallScreen ? (
        <Carousel showThumbs={false}>
          <div>
            <img src={img2} alt="" />
          </div>
          <div>
            <img src={img3} alt="" />
          </div>
          <div>
            <img src={img4} alt="" />
          </div>
        </Carousel>
      ) : (
        <div data-aos="fade-up" className="secImg">
          <div className="secImg1">
            <img src={img2} alt="" />
          </div>
          <div className="secImg2">
            <img src={img3} alt="" />
            <img src={img4} alt="" />
          </div>
        </div>
      )}
        <div data-aos="fade-up" className="card grid">
            <h2>Premium Room</h2>
            <span>Upgraded beds, better views, larger space, some with separate areas/balconies offer enhanced comfort for a more luxurious experience a comfortable stay for 2.</span>
            <h3>Amenities</h3>
            <ul>
                <li>Pick Up / Drop Off Service</li>
                <li>Room Jacuzzi</li>
                <li>Complimentary Wi-Fi</li>
                <li>24-Hour Room Service</li>
                <li>Complimentary Meals (Breakfast, Lunch, Dinner)</li>
                <li>Custom-Stocked Fridge/ Mini-Bar</li>
                <li>Complimentary Movie Library</li>
                <li>Non-alcoholic and alcoholic drinks upon request</li>
                <li>Sex Chair</li>
                <li>Toys & Costumes for role play</li>
            </ul>
            <h3>Premium Exclusive</h3>
            <ul>
                <li>Massage Therapy</li>
                <li>Hookup Room</li>
            </ul>
            <div className="buttons flex">
                <Link to='/book'>
                    <button className="btn">Book now</button>
                </Link>
                <Link to='/stay'>
                    <button className="btn">more rooms and suites</button>
                </Link>
            </div>
        </div>
    </section>
  )
}

export default Premium